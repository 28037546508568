/* Add this CSS to your stylesheets (e.g., styles.css) */

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.input-field {
    width: 250px;
    height: 40px;
    margin: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.login-btn {
    width: 150px;
    height: 40px;
    margin: 10px;
    padding: 8px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.login-btn:hover {
    background-color: #0056b3;  
}

.error-border {
    border: 2px solid red;
}