.button-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.small-image {
    max-width: 80%;
    max-height: 80%;
}
