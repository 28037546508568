/* Profile.css */

.profile-container {
    padding: 1em;
    font-family: 'Roboto', sans-serif;
    color: #2c3e50;
}

.profile-container h1 {
    font-size: 2em;
    font-weight: 500;
    margin-bottom: 0.5em;
    color: #34495e;
}


.profile-container h2 {
    font-size: 1.5em;
    font-weight: 400;
    margin-bottom: 1em;
    color: #7f8c8d;
}

.test-result {
    padding: 1em;
    margin-bottom: 1em;
    border: 1px solid #ecf0f1;
    border-radius: 5px;
}

.test-result h3 {
    font-size: 1.2em;
    margin-bottom: 0.5em;
    color: #16a085;
}

.test-result p {
    font-size: 1em;
    margin-bottom: 0.25em;
    color: #2c3e50;
}

.correct-answers {
    color: #27ae60;
}

.wrong-answers {
    color: #c0392b;
}

.test-result h3 {
    color: #8e44ad;
}

.profile-show-password-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px; /* You can adjust the font size */
    color: #333; /* You can adjust the color */
}

.change-password-title{
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 0.5em;
    color: #c0392b;
}

.profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .change-password-btn {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .change-password-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .change-password-container {
    background: white;
    padding: 20px;
    /* padding-top: 0; */
    border-radius: 10px;
    position: relative;
  }
  
  .close-modal-btn {
    position: absolute;
    top: 20px;
    right: 25px;
  }
  
.first-wrapper {
    margin-top: 50px;
}

/* Add this to your Profile.css file */

.delete-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    width: 80%; /* You can adjust this width to suit your needs */
    max-width: 400px; /* Optional: You can adjust this max-width to suit your needs */
}

.delete-modal-content h2 {
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 0.15em;
    color: #c0392b;
}

.delete-modal-content p {
    font-size: 1em;
    margin-bottom: 0.25em;
    color: #2c3e50;
}

.delete-modal-content input {
    width: 80%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ecf0f1;
    border-radius: 5px;
    font-size: 1em;
    text-align: center;
}

.delete-modal-content button {
    font-weight: 600;
    background-color: #17a2b8; /* Light blue background */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px; /* Spacing between the buttons */
    border-radius: 5px; /* Match border radius with other elements */
}

.delete-modal-content button:hover {
    background-color: #138496; /* Darker blue for hover effect */
}

.delete-modal-content button:last-child {
    margin-right: 0;
    background-color: rgb(239 68 68);
}

.delete-modal-content button:last-child:hover {
    background-color: rgb(185 28 28);
}

.test-result-delete {
    color: rgb(239 68 68);
    text-transform: uppercase;
}

.test-result-retest {
    color: rgb(140, 187, 17);
    text-transform: uppercase;
    font-style: italic;
}

.test-result-delete:hover, .test-result-retest:hover{
    letter-spacing: .1em;
    font-weight: bold;
}
